import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Offcanvas,
  Modal,
  Nav,
  Navbar,
  Dropdown,
} from "react-bootstrap";
import { logoutUser } from "../redux/auth/actions";
import { CasinoProviders } from "../lib/data";
import { getUserBet } from "../redux/sports/actions";
import ToggleIcon from "../assets/images/icons/toggle.svg";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import numberWithCommas from "../utils/numbersWithComma";
import Profile from "../assets/images/icons/user-profile-wht.png";
import MyBets from "../assets/images/icons/my-bets-wht.png";
import Bonus from "../assets/images/icons/bonus-wht.png";
import Referral from "../assets/images/icons/referral-wht.png";
import Statement from "../assets/images/icons/statement-wht.png";
import SPMGames from "../assets/images/icons/spm-games.png";
import Withdraw from "../assets/images/icons/cash-withdrawal.png";
import Logout from "../assets/images/icons/signout-wht.png";

import GamesSearchModal from "../views/components/GamesSearchModal";
import { getNotifications } from "../redux/app/actions";
import { AiOutlineClose } from "react-icons/ai";
import { VscVerifiedFilled } from "react-icons/vsc";

import ProfileIcon from "../assets/images/icons/man.png";

import Gift from "../assets/images/icons/gift.png";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);
  const [showLeftbarToggle, setShowLeftbarToggle] = useState(false);
  const [websiteNotifications, setWebsiteNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(true);
  const htmlElement = document.documentElement;

  useEffect(() => {
    loadNotifications();
    return () => {};
  }, []);

  // useEffect(() => {
  //   if (showLeftbarToggle) {
  //     // htmlElement.style.overflow = "hidden";
  //     document.body.classList.add("leftbat-opened");
  //   } else {
  //     // htmlElement.style.overflow = "auto";
  //     document.body.classList.remove("leftbat-opened");
  //   }
  // }, [showLeftbarToggle]);

  const loadNotifications = async () => {
    // get notifications
    const notifications = await getNotifications();
    if (notifications && notifications?.data?.data) {
      setShowNotifications(true);
      setWebsiteNotifications(notifications?.data?.data);
    }
  };

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };

  const handleToggleClick = () => {
    const realTimeValue = !showLeftbarToggle;
    if (!document.body.classList.contains("leftbat-opened")) {
      // htmlElement.style.overflow = "hidden";
      document.body.classList.add("leftbat-opened");
    } else {
      // htmlElement.style.overflow = "auto";
      document.body.classList.remove("leftbat-opened");
    }
    setShowLeftbarToggle(realTimeValue);
  };

  return (
    <>
      {(websiteNotifications.length && showNotifications && (
        <div className="marqu">
          <marquee>
            {websiteNotifications.map((notf, idx) => (
              <label style={{ marginRight: "30px" }} key={idx}>
                {notf?.content}
              </label>
            ))}
          </marquee>
          <span onClick={() => setShowNotifications(false)}>
            <AiOutlineClose />
          </span>
        </div>
      )) ||
        null}
      <header className="header aftrlgn">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="leftbar_toggle d-lg-block d-none">
                    <Button
                      variant="primary"
                      onClick={() => handleToggleClick()}
                    >
                      <img src={ToggleIcon} />
                    </Button>
                  </div>
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                </div>

                <div className="headerRight">
                  <div className="d-md-block d-none">
                    <GamesSearchModal />
                  </div>
                  <div className="BalanceDropdown">
                    <Dropdown align="end" className="me-2 ">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        Bal: <span>{numberWithCommas(wallet?.cash || 0)}</span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="tel:{user?.mobileno}">
                          <b>{user?.mobileno}</b>
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <h6>Balance</h6>
                          <span>
                            INR {numberWithCommas(wallet?.balance || 0)}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Cash</h6>
                          <span>INR {numberWithCommas(wallet?.cash || 0)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#"
                          onClick={() => setShowBetModal(true)}
                        >
                          <h6>Exposure</h6>
                          <span>
                            INR {numberWithCommas(exposureAmount || 0)}
                          </span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bonus">
                          <h6>Bonus</h6>
                          <span>INR {numberWithCommas(wallet?.coins)}</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/gatewaylist"
                          className="deposit btn-primary btn"
                        >
                          Deposit
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <ul>
                      <li className="">
                        <a href="/gatewaylist" className="deposit">
                          <span className="d-md-block d-none"> Deposit</span>
                          <span className="d-md-none d-block"> +</span>
                        </a>
                      </li>
                    </ul>
                  </div>

                  <ul className="BonuSection">
                    <li>
                      <a href="/bonus">
                        <img src={Gift} />
                      </a>
                    </li>
                  </ul>

                  <div className="MenuDropdown">
                    <Dropdown align="end" className=" ">
                      <Dropdown.Toggle variant="success" id="dropdown-basic1">
                        <img src={ProfileIcon} />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="/profile">
                          <img src={Profile} alt="profile" />
                          <span>Profile</span>
                        </Dropdown.Item>{" "}
                        <Dropdown.Item href="/withdraw">
                          <img src={Withdraw} alt="profile" />
                          <span>Withdraw</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/mybets">
                          <img src={MyBets} alt="my bets" />
                          <span>My Bets</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/bonus">
                          <img src={Bonus} alt="bonus" />
                          <span>Bonus</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/referral">
                          <img src={Referral} alt="referral" />
                          <span>Referral</span>
                        </Dropdown.Item>
                        <Dropdown.Item href="/cashier">
                          <img src={Statement} alt="account statement" />
                          <span>Account Statement</span>
                        </Dropdown.Item>
                        <DropdownItem onClick={() => dispatch(logoutUser())}>
                          <img src={Logout} alt="logout" />
                          <span>Sign Out</span>
                        </DropdownItem>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Offcanvas show={show} onHide={() => setShow(false)} placement={"end"}>
          <Offcanvas.Body>
            <ul className="menu-items">
              <li>
                <a href="/profile">
                  <img src={Profile} alt="profile" />
                  <span>Profile</span>
                </a>
              </li>
              <li>
                <a href="/mybets">
                  <img src={MyBets} alt="my bets" />
                  <span>My Bets</span>
                </a>
              </li>
              <li>
                <a href="/bonus">
                  <img src={Bonus} alt="bonus" />
                  <span>Bonus</span>
                </a>
              </li>
              <li>
                <a href="/referral">
                  <img src={Referral} alt="referral" />

                  <span>Referral</span>
                </a>
              </li>
              <li>
                <a href="/cashier">
                  <img src={Statement} alt="account statement" />
                  <span>Account Statement</span>
                </a>
              </li>
              <li>
                <a href="/spmgames">
                  <img src={SPMGames} alt="SPM games" />
                  <span>SPM Games</span>
                </a>
              </li>
              <li>
                <a href="/kyc">
                  <VscVerifiedFilled />
                  <span>KYC</span>
                </a>
              </li>
              <li onClick={() => dispatch(logoutUser())}>
                <a href="/">
                  <img src={Logout} alt="logout" />
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
            <ul className="social_links">
              <li>
                <a target="_blank" href={`${appDetails?.TELEGRAM}`}>
                  <TelegramIcon />
                </a>
              </li>
              {/* <li>
              <div
                onClick={() =>
                  window.open(https://wa.me/+917888846060, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
              <li>
                <a
                  className="panel"
                  href={
                    appDetails?.WHATS_APP_URL != ""
                      ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                      : "#"
                  }
                  target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
                >
                  <WhatsappIcon />
                </a>
              </li>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>

        <Modal
          className="betslip_popup outer_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Exposure amount</th>
                      <th scope="col">Match name</th>
                      <th scope="col">Ref match ID</th>
                      <th scope="col">Total bets</th>
                    </tr>
                  </thead>
                  <tbody>
                    {betMatches &&
                      betMatches.length &&
                      betMatches.map((item, index) => {
                        return (
                          <tr
                            onClick={() => {
                              handleRowClick(item);
                            }}
                          >
                            <th scope="row">{index + 1}</th>
                            <td>{item?.exposureAmount}</td>
                            <td>{item?.matchName}</td>
                            <td>{item?.refMatchId}</td>
                            <td>{item?.totalBets}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="betslip_popup inner_popup"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={showUserBetModal}
        >
          <Modal.Body>
            {" "}
            <main className="main">
              <div className="container table-responsive">
                <table className="table text-white">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Odds</th>
                      <th scope="col">Stack</th>
                      <th scope="col">Selection type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Create date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userBets &&
                      userBets.length &&
                      userBets.map((item, index) => {
                        return (
                          <tr>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.odds}</td>
                            <td>{item?.stack}</td>
                            <td>{item?.selectionType}</td>
                            <td>{item?.status}</td>
                            <td>{item?.createdAt}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </main>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      </header>
    </>
  );
};

export default HeaderAfterLogin;
