import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { loginUser, demoLogin } from "../../../redux/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";

import LoginBanner from "../../../assets/images/banner/login.webp";
import LoginBannerMob from "../../../assets/images/banner/loginmob.webp";

import { checkIfDemoUserExist } from "../../../redux/app/actions";

const LoginModal = ({ show, handleClose, handleModalShow }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const { appDetails } = useSelector((state) => state.app);

  const [isDemoUserExist, setIsDemoUserExist] = useState(false);

  useEffect(() => {
    checkDemoUser();
  }, []);

  const checkDemoUser = async () => {
    const response = await checkIfDemoUserExist();
    if (response && response?.data?.error === false) {
      setIsDemoUserExist(response?.data?.data?.hasDemoUsers === true);
    }
  };

  const validateData = () => {
    const { username, password } = formData;
    const newErrors = {};
    if (!username || username === "") {
      newErrors.username = "Username cannot be empty";
    }
    if (!password || password === "") {
      newErrors.password = "Password cannot be empty";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorData = validateData();
    if (Object.keys(errorData).length > 0) {
      setErrors(errorData);
    } else {
      let loginData = { ...formData };
      dispatch(
        loginUser(loginData, () =>
          handleClose(APP_CONST.AUTH_MODAL_ACTION_TYPE.AUTO)
        )
      );
    }
  };

  const handleChange = (e) => {
    let fieldName = e.target.name;
    let fieldValue = e.target.value;
    let tempFormData = { ...formData, [fieldName]: fieldValue };
    setFormData(tempFormData);
  };

  const loginDemoUser = (e) => {
    e.preventDefault();
    dispatch(demoLogin());
  };

  return (
    <>
      <Modal
        show={show}
        className={"steps-canvas login-modal"}
        onHide={() => {
          console.log("working");
        }}
        placement={"end"}
      >
        <div className="modalSection">
          <div className="LoginBanner">
            <img src={LoginBanner} alt="" className="d-md-block d-none" />
            <img src={LoginBannerMob} alt="" className="d-md-none d-block" />
          </div>
          <div className="LoginRightPart">
            <Modal.Header
              closeButton
              closeVariant="white"
              onClick={() => handleClose()}
            >
              <Modal.Title> Sign In</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="login-section">
                <div className="Login-rightPart">
                  <div className="form-steps">
                    <Form>
                      <Form.Group className="mb-3" controlId="formBasicName">
                        <Form.Control
                          type="text"
                          placeholder="Enter Username*"
                          name="username"
                          onChange={handleChange}
                          isInvalid={!!errors.username}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors?.username}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group
                        className="mb-3 position-relative"
                        controlId="formBasicPassword"
                      >
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder="Password*"
                          name="password"
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                        />
                        <span
                          className="icon"
                          onClick={() => setShowPassword((s) => !s)}
                        >
                          {showPassword ? <IoIosEyeOff /> : <IoIosEye />}
                        </span>
                        <Form.Control.Feedback type="invalid">
                          {errors?.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div className="d-flex justify-content-end mb-3">
                        <a
                          className="form-link"
                          href="#"
                          onClick={() => {
                            handleClose(
                              APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD,
                              "auto"
                            );
                            handleModalShow(
                              APP_CONST.AUTH_MODAL.FORGOTO_PASSWORD
                            );
                          }}
                        >
                          Password Recovery
                        </a>
                      </div>
                      <Button
                        className="btn-primary"
                        variant=""
                        type="submit"
                        onClick={handleSubmit}
                      >
                        Sign In
                      </Button>
                    </Form>

                    {isDemoUserExist && (
                      <>
                        <div className=" mb-1">
                          Need Demo? Just Click the button below.
                        </div>
                        <div className=" ">
                          <Button
                            variant="primary"
                            className="mt-0"
                            onClick={loginDemoUser}
                          >
                            Demo Login
                          </Button>
                        </div>
                      </>
                    )}

                    <div className="sign-up ">
                      New to B11?
                      <a
                        href="#"
                        onClick={() => {
                          handleClose(APP_CONST.AUTH_MODAL.LOGIN, "auto");
                          handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
                        }}
                      >
                        Create account
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
      {show && <div className="modal-overlay"></div>}
    </>
  );
};
export default LoginModal;
